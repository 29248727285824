//import { Component } from "react"
//import pbjs from 'prebid.js';

// //Load Bid Adapaters
// import 'prebid.js/modules/aolBidAdapter'; //One by AOL
// import 'prebid.js/modules/openxBidAdapter'; //OpenX
// import 'prebid.js/modules/tripleliftBidAdapter'; //Triplelift
// import 'prebid.js/modules/yieldoneBidAdapter'; //YeildOne
// //import 'prebid.js/modules/yahoosspBidAdapter'; //Yahoo SSP
// import 'prebid.js/modules/appnexusBidAdapter'; //Appnexus
// import 'prebid.js/modules/sovrnBidAdapter'; //Sovrn
// import 'prebid.js/modules/teadsBidAdapter'; //Teads
// import 'prebid.js/modules/adagioBidAdapter'; //Adagio
// import 'prebid.js/modules/ixBidAdapter'; //Index Exchange
// import 'prebid.js/modules/sharethroughBidAdapter'; //Share Through
// import 'prebid.js/modules/onetagBidAdapter'; //OneTag
// import 'prebid.js/modules/criteoBidAdapter'; //Criteo
// import 'prebid.js/modules/pubmaticBidAdapter'; //Pubmatic
// import 'prebid.js/modules/justpremiumBidAdapter'; //Just Premium
// //import 'prebid.js/modules/'; //One Fifty Media
// import 'prebid.js/modules/adfBidAdapter'; //Ad Form

// //Anayltic Adapters
// import 'prebid.js/modules/adagioAnalyticsAdapter'; //Adagio Analytics

// //Recommended modules
// import 'prebid.js/modules/consentManagement'; //Consent Mangement
// import 'prebid.js/modules/gdprEnforcement'; //GDPR Enforcement

// //User ID Modules
// import 'prebid.js/modules/lotamePanoramaIdSystem'; //Lotame ID
// import 'prebid.js/modules/id5IdSystem'; //ID5 ID
// import 'prebid.js/modules/identityLinkIdSystem'; //Identity Link ID

// //General Modules
// import 'prebid.js/modules/currency'; //currency
// import 'prebid.js/modules/priceFloors'; //Price Floors

//import { DebugModeLog } from "../utils";
import { CPMEvents } from "./cpm-events";

export const fetchHeaderBids = (apstagSlots, adUnits, bidTimeout) => {
  //const abtest = localStorage.getItem("abtest");

  // declare bidders
  const bidders = ["a9", "prebid"];

  // create a requestManager to keep track of bidder state to determine when to send ad server
  // request and what apstagSlots to request from the ad server
  let requestManager = {
    adserverRequestSent: false,
  };

  /* Custom Refresh 
  requestManager["StickyBanner"] = {};
  requestManager["LeftColumn"] = {};
  requestManager["RightColumn"] = {};
  */

  // loop through bidder array and add the bidders to the request manager
  bidders.forEach(function (bidder) {
    requestManager[bidder] = false;
    /* Custom Refresh 
    requestManager["StickyBanner"][bidder] = false;
    requestManager["LeftColumn"][bidder] = false;
    requestManager["RightColumn"][bidder] = false;
    */
  });

  /* Custom Refresh 
  requestManager["StickyBanner"]["request"] = false;
  requestManager["LeftColumn"]["request"] = false;
  requestManager["RightColumn"]["request"] = false;
  */

  // return true if all bidders have returned
  function allBiddersBack() {
    var allBiddersBack =
      // if length is equal to bidders, all bidders are back
      bidders
        // get the booleans from the object
        .map(function (bidder) {
          return requestManager[bidder];
        })
        // get rid of false values - indicates that the bidder has responded
        .filter(Boolean).length === bidders.length;

    return allBiddersBack;
  }

  // handler for header bidder responses
  function headerBidderBack(bidder) {
    // return early if request to adserver is already sent
    if (requestManager.adserverRequestSent === true) {
      return;
    }
    // flip bidder back flag
    if (bidder === "a9") {
      requestManager.a9 = true;
    } else if (bidder === "prebid") {
      requestManager.prebid = true;
    }
    // if all bidders are back, send the request to the ad server
    if (allBiddersBack()) {
      sendAdserverRequest();
    }
  }

  // actually get ads from GAM
  function sendAdserverRequest() {
    // return early if request already sent
    if (requestManager.adserverRequestSent === true) {
      return;
    }

    // flip the boolean that keeps track of whether the adserver request was sent
    requestManager.adserverRequestSent = true;
    // flip pbjs boolean to signal to pbjs the ad server has already been called
    window.pbjs.adserverRequestSent = true;

    // flip boolean for adserver request to avoid duplicate requests
    requestManager.sendAdserverRequest = true;

    // set bid targeting and make ad request to GAM
    window.googletag.cmd.push(function () {
      window.apstag.setDisplayBids();
      window.pbjs.setTargetingForGPTAsync();
      window.googletag.pubads().refresh();
    });
  }

  /** Refresh Sticky start 
  let previousBiddersLength = 0;
  let defaultDelay = 13; //seconds
  let longDelay = 33; //seconds

  let isPageVisible = true;
  let refreshedAdUnits = {};

  // Function to start the timer
  function startTimer(adUnit, timerLength) {
    let timeRemaining = timerLength;
    DebugModeLog(`CR: (v4) Timer started for: ${adUnit} 🚀`);
    if (refreshedAdUnits[adUnit]?.remainingTime) {
      timeRemaining = refreshedAdUnits[adUnit].remainingTime;
    }

    refreshedAdUnits[adUnit] = {
      intervalId: setInterval(() => {
        // If ad unit comes back empty cancel timer
        if (timerTracker[adUnit].isEmpty) {
          console.log(
            `CR: (v4) ${adUnit}⏱️: TIMER STOPPED/NOT STARTED, adunit not filled`
          );
          clearInterval(refreshedAdUnits[adUnit].intervalId);
          return;
        }

        if (!timerTracker[adUnit].isEmpty) {
          DebugModeLog(`CR: (v4) ${adUnit}⏱️: ${timeRemaining}`);
          if (timeRemaining <= 0 && isPageVisible) {
            initialiseRefresh(adUnit);
            if (refreshedAdUnits[adUnit].newTimer) {
              timeRemaining = refreshedAdUnits[adUnit].newTimer;
            } else {
              timeRemaining = timerLength;
            }
          } else {
            timeRemaining--;
          }
        }

        refreshedAdUnits[adUnit].remainingTime = timeRemaining;
        DebugModeLog(refreshedAdUnits[adUnit]);
      }, 1000),
      remainingTime: timeRemaining,
    };
  }

  function initialiseRefresh(adUnit) {
    if (window.screen.width > 812 && adUnit === "LeftColumn") {
      customAdunitRefresh(adUnit);
    }
    if (window.screen.width > 1024 && adUnit === "RightColumn") {
      customAdunitRefresh(adUnit);
    }
    if (adUnit === "StickyBanner") {
      customAdunitRefresh(adUnit);
    }
    window.addEventListener("assertive_logImpression", function (event) {
      if (event.data.payload.pageView_refreshCount > 0) {
        event.data.payload.custom_6 = "refreshed";
      }
    });
  }

  // Handle page visibility change
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      DebugModeLog("CR: (v4) User Moved away from page 📵");
      isPageVisible = false;
    } else {
      DebugModeLog("CR: (v4) User came back to page 💚");
      isPageVisible = true;
    }
  });

  let renderedSlots = [];
  let timerTracker = {
    StickyBanner: {
      isRendered: false,
      isEmpty: false,
      isViewable: false,
      isTimerStarted: false,
    },
    LeftColumn: {
      isRendered: false,
      isEmpty: false,
      isViewable: false,
      isTimerStarted: false,
    },
    RightColumn: {
      isRendered: false,
      isEmpty: false,
      isViewable: false,
      isTimerStarted: false,
    },
  };

  function checkReadyToStart() {
    // Checks if timer is ready to start based on conditions
    // Function is called when adunit is rendered and becomes viewable so all adUnits will eventually start their timer.
    for (let key in timerTracker) {
      if (timerTracker.hasOwnProperty(key)) {
        // Set different delay for StickyBanner
        // defaultDelay = key === "StickyBanner" ? 36 : defaultDelay;

        if (!timerTracker[key].isTimerStarted && !timerTracker[key].isEmpty) {
          // Rendered & empty & viewable
          if (
            timerTracker[key].isRendered &&
            !timerTracker[key].isEmpty &&
            timerTracker[key].isViewable
          ) {
            startTimer(key, defaultDelay);
            timerTracker[key].isTimerStarted = true;
          }
        }
      }
    }
  }

  if (abtest === `ab.${process.env.GATSBY_SHORT_DOMAIN}.c`) {
    // Start custom adunit refresh based on slots rendered and/or impressionViewable
    window.googletag
      .pubads()
      .addEventListener("impressionViewable", (event) => {
        const slot = event.slot;
        for (let key in timerTracker) {
          if (timerTracker.hasOwnProperty(key)) {
            if (key === slot.getSlotElementId()) {
              timerTracker[key].isViewable = true;
            }
          }
        }
        checkReadyToStart();
      });

    window.googletag
      .pubads()
      .addEventListener("slotRenderEnded", function (event) {
        const slot = event.slot;
        let adUnitNamesBeingRefreshed = Object.keys(timerTracker);

        if (adUnitNamesBeingRefreshed.includes(slot.getSlotElementId())) {
          for (let key in timerTracker) {
            if (timerTracker.hasOwnProperty(key)) {
              if (key === slot.getSlotElementId()) {
                timerTracker[key].isRendered = true;
                timerTracker[key].isEmpty = event.isEmpty;
              }
            }
          }
          renderedSlots.push(slot.getSlotElementId());
          checkReadyToStart();
        }
      });
  }

  function customAdunitRefresh(adUnitName) {
    // CR = Conditional Refresh
    DebugModeLog("CR: -- START -- ");

    runAdUnitRefresh(adUnitName);

    const winners = window.pbjs.getAllWinningBids();
    const adUnitWinners = winners.filter(
      (bidder) => bidder.adUnitCode === adUnitName
    );
    const latestWinner = adUnitWinners.length ? adUnitWinners.slice(-1)[0] : [];
    let currentBiddersLength = adUnitWinners.length;

    DebugModeLog("CR: 🏆 Winner: ", latestWinner["bidder"]);

    if (currentBiddersLength > previousBiddersLength) {
      // if currentBiddersLength is bigger than previousBiddersLength it means we have a new bid from prebid
      switch (latestWinner["bidderCode"]) {
        case "ix":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: IX won on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "teads":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: Teads won on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "justpremium":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: Justpremium won on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "criteo":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: Criteo won on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        default:
          refreshedAdUnits[adUnitName].newTimer = defaultDelay;
          DebugModeLog(
            `CR: Other bidder won on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
      }
    } else {
      // No prebid winners
      refreshedAdUnits[adUnitName].newTimer = defaultDelay;
      DebugModeLog(
        `CR: No prebid winner on ${adUnitName}, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
      );
    }
    previousBiddersLength = adUnitWinners.length;
  }

  function runAdUnitRefresh(adUnitName) {
    fetchAdUnitBids(adUnitName);

    DebugModeLog("Request ", requestManager);

    setTimeout(function () {
      if (requestManager[adUnitName].request === false) {
        DebugModeLog("Refresh timedout");
        sendAdserverRequestForAdUnit(adUnitName);
      }

      requestManager[adUnitName].request = false;
    }, 3000);
  }

  function fetchAdUnitBids(adUnitName) {
    window.apstag.fetchBids(
      {
        slots: [window.apsAdUnits.find((ad) => ad.slotID === adUnitName)],
        timeout: bidTimeout,
      },
      function (bids) {
        headerBidderBackForAdunit("a9", adUnitName);
      }
    );
    window.pbjs.que.push(() => {
      window.pbjs.requestBids({
        timeout: 2700,
        adUnits: [window.pbjsAdUnits.find((ad) => ad.code === adUnitName)],
        bidsBackHandler: function (bidResponses) {
          headerBidderBackForAdunit("prebid", adUnitName);
        },
      });
    });
  }

  function bidBackForAdunit(adunit) {
    return requestManager[adunit]["a9"] && requestManager[adunit]["prebid"];
  }

  function headerBidderBackForAdunit(bidder, adunit) {
    // return early if request to adserver is already sent

    DebugModeLog("Bids back for " + bidder + " and adunit " + adunit);

    if (requestManager[adunit]["request"] === true) {
      return;
    }

    requestManager[adunit][bidder] = true;

    // if all bidders are back, send the request to the ad server
    if (bidBackForAdunit(adunit)) {
      DebugModeLog("Bids back?", requestManager);
      sendAdserverRequestForAdUnit(adunit);
    }
  }

  function sendAdserverRequestForAdUnit(adunit) {
    // return early if adunit request sent
    if (requestManager[adunit]["request"] === true) {
      return;
    }

    // flip the boolean that keeps track of whether the adserver request was sent requestManager.adserverRequestSent = true;
    // flip pbjs boolean to signal to pbjs the ad server has already been called pbjs.adserverRequestSent = true;

    // flip boolean for adserver request to avoid duplicate requests
    requestManager[adunit]["request"] = true;

    // set bid targeting and make ad request to GAM
    window.googletag.cmd.push(function () {
      //key values
      window.ads[adunit].setTargeting("refresh", "1");
      window.apstag.setDisplayBids([adunit]);
      window.pbjs.setTargetingForGPTAsync([adunit]);
      window.googletag.pubads().refresh([window.ads[adunit]]);

      DebugModeLog("Refresh complete");
    });

    ClearRefresh(adunit);
  }

  function ClearRefresh(adunitName) {
    requestManager[adunitName].a9 = false;
    requestManager[adunitName].prebid = false;

    DebugModeLog("Cleared ", requestManager);
  }

  /** Refresh Sticky end */

  function requestBids(apstagSlots, adUnits, bidTimeout) {
    // eslint-disable-next-line no-use-before-define
    var PREBID_TIMEOUT = PREBID_TIMEOUT || 2700;

    if (PREBID_TIMEOUT > bidTimeout) {
      PREBID_TIMEOUT = bidTimeout - 300;
    }

    // fetch apstag bids, set bid targting, then call headerBidderBack
    // to get the ads for the first time
    window.apstag.fetchBids(
      {
        slots: apstagSlots,
        timeout: bidTimeout,
      },
      function (bids) {
        headerBidderBack("a9");
      }
    );

    // request bids from prebid
    window.pbjs.que.push(() => {
      window.pbjs.onEvent("auctionEnd", function () {
        if (typeof CPMEvents === "undefined") {
          return;
        }
        console.log("auctionEnd_1");
        CPMEvents();
      });

      window.pbjs.onEvent("bidWon", function (data) {
        if (typeof CPMEvents === "undefined") {
          return;
        }

        console.log("bidWon_1");
        CPMEvents();
      });

      //Check for initial ads
      let initialAds = window.pbjs.adUnits.find(
        (ad) => ad.code === "LeaderBelowTitle"
      );
      if (!initialAds) {
        window.pbjs.addAdUnits(adUnits);
      }

      window.pbjs.bidderSettings = {
        standard: {
          storageAllowed: true,
        },
        adagio: {
          storageAllowed: true,
        },
        appnexus: {
          storageAllowed: true,
        },
        criteo: {
          storageAllowed: true,
        },
        ix: {
          storageAllowed: true,
        },
        justpremium: {
          storageAllowed: true,
        },
        openx: {
          storageAllowed: true,
        },
        onemobile: {
          storageAllowed: true,
        },
        onetag: {
          storageAllowed: true,
        },
        sovrn: {
          storageAllowed: true,
        },
        triplelift: {
          storageAllowed: true,
        },
        yieldone: {
          storageAllowed: true,
        },
        sharethrough: {
          storageAllowed: true,
        },
        taboola: {
          storageAllowed: true,
        },
        rise: {
          storageAllowed: true,
        },
        ogury: {
          storageAllowed: true,
        },
        vidazoo: {
          storageAllowed: true,
        },
        smartadserver: {
          storageAllowed: true,
        },
        districtm: {
          bidCpmAdjustment: function (bidCpm) {
            return bidCpm * 0.85;
          },
          storageAllowed: true,
        },
        adform: {
          bidCpmAdjustment: function (bidCpm) {
            return bidCpm * 0.86;
          },
          storageAllowed: true,
        },
      };

      const customConfigPriceSetup = {
        buckets: [
          {
            precision: 2,
            max: 5,
            increment: 0.01,
          },
          {
            precision: 2,
            max: 20,
            increment: 0.05,
          },
          {
            precision: 2,
            max: 70,
            increment: 0.5,
          },
        ],
      };

      window.pbjs.enableAnalytics([
        {
          provider: "adagio",
        },
      ]);

      window.pbjs.setConfig({
        userSync: {
          syncsPerBidder: 50,
        },
        consentManagement: {
          cmpApi: "iab",
          timeout: 10000, // in milliseconds
          allowAuctionWithoutConsent: true,
        },
        useBidCache: true,
        enableSendAllBids: true,
        priceGranularity: customConfigPriceSetup,
        currency: {
          // enables currency feature
          adServerCurrency: "EUR",
        },
        targetingControls: {
          allowTargetingKeys: [
            "BIDDER",
            "AD_ID",
            "PRICE_BUCKET",
            "SIZE",
            "DEAL",
            "SOURCE",
            "FORMAT",
            "UUID",
            "CACHE_ID",
            "CACHE_HOST",
            "ADOMAIN",
          ],
        },
      });

      window.pbjs.requestBids({
        timeout: PREBID_TIMEOUT,
        adUnits: adUnits,
        bidsBackHandler: function (bidResponses) {
          headerBidderBack("prebid");
        },
      });

      const floorTargeting = (cpm, buckets) => {
        let targeting = undefined;
        function inRange(x, min, max) {
          return x > min && x <= max;
        }
        buckets.forEach((bucket, i) => {
          let min = i > 0 ? buckets[i - 1].max : 0;
          if (inRange(cpm, min, bucket.max)) {
            targeting = (Math.floor(cpm / bucket.inc) * bucket.inc).toFixed(2);
          }
        });
        return targeting
          ? targeting
          : buckets[buckets.length - 1].max.toFixed(2);
      };

      window.pbjs.onEvent("auctionEnd", function (arg) {
        window.googletag.cmd.push(function () {
          let gptSlots = window.googletag.pubads().getSlots();
          arg.adUnitCodes.forEach(function (adUnitCode) {
            let gptAdUnit = gptSlots.filter(
              (slot) => slot.getSlotElementId() === adUnitCode
            )[0];
            let winner = window.pbjs.getHighestCpmBids(adUnitCode);
            let priceBuckets = [
              { max: 5, inc: 0.05 },
              { max: 10, inc: 0.1 },
            ];
            let gam_val =
              winner.length > 0
                ? floorTargeting(winner[0].cpm, priceBuckets)
                : 0;
            gptAdUnit.setTargeting("prkv", Math.min(gam_val, 10.0).toFixed(2));
          });
        });
      });
    });
  }

  requestBids(apstagSlots, adUnits, bidTimeout);

  window.setTimeout(function () {
    sendAdserverRequest();
  }, bidTimeout);
};
